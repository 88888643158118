import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class AboutUs extends Component {
  render() {
    return (
      <div className="about-us">
        <div className="menu">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item"><Link to={'/adventure-packs'}>Adventure Packs</Link></li>
            <li className="list-group-item"><Link to={'/quests'}>Quests</Link></li>
            <li className="list-group-item"><Link to={'/starter-gear'}>Starter Gear</Link></li>
          </ul>
        </div>
        <p>
          The Vault of Kundarak is here to bring you tools and resources for DDO.
        <li>
            We have a one-click open-source Launcher: <a href="https://gitlab.com/VaultOfKundarak/launcher/-/releases">Download Here</a>
          </li>
          <br />
          To contact us, please join our discord: <a href={"https://discord.gg/bfMZnbz"}>https://discord.gg/bfMZnbz</a>
        </p>
      </div>
    );
  }
}

export default AboutUs;