import React, { Component } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-157798016-1')

export default function withTracker(WrappedComponent, options = {}) {

  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      // do we track local dev?
      if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
        console.log("Dev mode.")
      } else {
        trackPage(page);
      }
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;
      if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
        console.log("Dev mode.")
      } else if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}