export default class ServerConfig {
    static serverConfigInstance = null;
    
    /**
     * @returns {ServerConfig}
     */
    static getInstance() {
        if (ServerConfig.serverConfigInstance == null) {
            ServerConfig.serverConfigInstance = new ServerConfig();
        }

        return this.serverConfigInstance;
    }

    getAPIEndpoint = () => {
        return process.env.REACT_APP_ITEM_SERVICE;
    }

    getContentEndpoint() {
        return process.env.REACT_APP_CONTENT_ENDPOINT;
    }
}