import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/Soundboard.css'

import ServerConfig from './ServerConfig'

function getType(sound) {
    if (sound.toLowerCase().endsWith(".ogg")) {
        return "audio/ogg"
    }
    if (sound.toLowerCase().endsWith(".wav")) {
        return "audio/wav"
    }
    if (sound.toLowerCase().endsWith(".mp3")) {
        return "audio/mpeg"
    }
}

function mapSoundList(baseUrl, list) {
    return list.map((s, i) => {
        let type = getType(s.fileName)
        return (
            <div key={s.name + i}>
                <div className="sound-name">{s.name}</div>
                <div>
                    <audio alt={"Cooldown: " + s.cooldown} controls preload="none">
                        <source src={baseUrl + s.fileName} type={type}></source>
                    </audio>
                </div>
            </div>
        )
    })
}

export class SentientJewel extends Component {
    ReportError = (error) => {
        this.props.ReportError(error)
    }

    render() {
        const { personalityName, equipSounds, xpSounds, filigreeUnlockedSounds, itemDamagedSounds, itemDestroyedSounds, unequipSounds,
            questBestowSounds, questCompleteSounds, monsterKillSounds, lowHealthSounds, takeDamageSounds, ownerDeathSounds,
            failedLockpickSounds, failedTrapSounds, triggerTrapSounds, failedLeverSounds, searchSuccessSounds, trapDisabledSounds,
            raisedFromDeathSounds, chestSounds, collectibleSounds, restShrineSounds, otherPlayerDeathSounds, badTimesSounds, placeSuspiciousSounds,
            placeNiceSounds, niceNpcSounds, shadyNpcSounds, lookElsewhereSounds, actionGoodSounds, actionEvilSounds, actionChaoticSounds,
            actionLawfulSounds, crowdControlSounds, eventGoodSounds, eventBadSounds, previewSounds, eventSuspiciousSounds, eventBetrayedSounds, placeCreepySounds } = this.props.sentience;

        let conf = ServerConfig.getInstance();
        let cdnUrl = conf.getContentEndpoint() + "/sounds/"

        return (
            <div className="soundboard">
                <div>Sentient Jewel Voice - {personalityName}</div>
                {equipSounds !== undefined && equipSounds.length > 0 ?
                    <div className="sound-type">Equip:
                        {mapSoundList(cdnUrl, equipSounds)}
                    </div>
                    : null}
                {xpSounds !== undefined && xpSounds.length > 0 ?
                    <div className="sound-type">XP:
                        {mapSoundList(cdnUrl, xpSounds)}
                    </div>
                    : null}
                {filigreeUnlockedSounds !== undefined && xpSounds.length > 0 ?
                    <div className="sound-type">Filigree Unlocked:
                        {mapSoundList(cdnUrl, filigreeUnlockedSounds)}
                    </div>
                    : null}
                {itemDamagedSounds !== undefined && xpSounds.length > 0 ?
                    <div className="sound-type">Item Damaged:
                        {mapSoundList(cdnUrl, itemDamagedSounds)}
                    </div>
                    : null}
                {itemDestroyedSounds !== undefined && itemDestroyedSounds.length > 0 ?
                    <div className="sound-type">Item Destroyed:
                        {mapSoundList(cdnUrl, itemDestroyedSounds)}
                    </div>
                    : null}
                {unequipSounds !== undefined && unequipSounds.length > 0 ?
                    <div className="sound-type">Un-equip:
                        {mapSoundList(cdnUrl, unequipSounds)}
                    </div>
                    : null}
                {questBestowSounds !== undefined && questBestowSounds.length > 0 ?
                    <div className="sound-type">Quest Bestowed:
                        {mapSoundList(cdnUrl, questBestowSounds)}
                    </div>
                    : null}
                {questCompleteSounds !== undefined && questCompleteSounds.length > 0 ?
                    <div className="sound-type">Quest Complete:
                        {mapSoundList(cdnUrl, questCompleteSounds)}
                    </div>
                    : null}
                {monsterKillSounds !== undefined && monsterKillSounds.length > 0 ?
                    <div className="sound-type">Monster Kill:
                        {mapSoundList(cdnUrl, monsterKillSounds)}
                    </div>
                    : null}
                {lowHealthSounds !== undefined && lowHealthSounds.length > 0 ?
                    <div className="sound-type">Low Health:
                        {mapSoundList(cdnUrl, lowHealthSounds)}
                    </div>
                    : null}
                {takeDamageSounds !== undefined && takeDamageSounds.length > 0 ?
                    <div className="sound-type">Take Damage:
                        {mapSoundList(cdnUrl, takeDamageSounds)}
                    </div>
                    : null}
                {ownerDeathSounds !== undefined && ownerDeathSounds.length > 0 ?
                    <div className="sound-type">Owner Death:
                        {mapSoundList(cdnUrl, ownerDeathSounds)}
                    </div>
                    : null}
                {failedLockpickSounds !== undefined && failedLockpickSounds.length > 0 ?
                    <div className="sound-type">Failed Lockpick:
                        {mapSoundList(cdnUrl, failedLockpickSounds)}
                    </div>
                    : null}
                {failedTrapSounds !== undefined && failedTrapSounds.length > 0 ?
                    <div className="sound-type">Failed Trap:
                        {mapSoundList(cdnUrl, failedTrapSounds)}
                    </div>
                    : null}
                {failedLockpickSounds !== undefined && failedLockpickSounds.length > 0 ?
                    <div className="sound-type">Failed Lockpick:
                        {mapSoundList(cdnUrl, failedLockpickSounds)}
                    </div>
                    : null}
                {triggerTrapSounds !== undefined && triggerTrapSounds.length > 0 ?
                    <div className="sound-type">Trigger Trap:
                        {mapSoundList(cdnUrl, triggerTrapSounds)}
                    </div>
                    : null}
                {failedLeverSounds !== undefined && failedLeverSounds.length > 0 ?
                    <div className="sound-type">Failed Lever:
                        {mapSoundList(cdnUrl, failedLeverSounds)}
                    </div>
                    : null}
                {searchSuccessSounds !== undefined && searchSuccessSounds.length > 0 ?
                    <div className="sound-type">Search Success:
                        {mapSoundList(cdnUrl, searchSuccessSounds)}
                    </div>
                    : null}
                {trapDisabledSounds !== undefined && trapDisabledSounds.length > 0 ?
                    <div className="sound-type">Failed Trap:
                        {mapSoundList(cdnUrl, trapDisabledSounds)}
                    </div>
                    : null}
                {raisedFromDeathSounds !== undefined && raisedFromDeathSounds.length > 0 ?
                    <div className="sound-type">Raised From Death:
                        {mapSoundList(cdnUrl, raisedFromDeathSounds)}
                    </div>
                    : null}
                {chestSounds !== undefined && chestSounds.length > 0 ?
                    <div className="sound-type">Chest:
                        {mapSoundList(cdnUrl, chestSounds)}
                    </div>
                    : null}
                {collectibleSounds !== undefined && collectibleSounds.length > 0 ?
                    <div className="sound-type">Collectible:
                        {mapSoundList(cdnUrl, collectibleSounds)}
                    </div>
                    : null}
                {restShrineSounds !== undefined && restShrineSounds.length > 0 ?
                    <div className="sound-type">Rest Shrine:
                        {mapSoundList(cdnUrl, restShrineSounds)}
                    </div>
                    : null}
                {otherPlayerDeathSounds !== undefined && otherPlayerDeathSounds.length > 0 ?
                    <div className="sound-type">Other Player Death:
                        {mapSoundList(cdnUrl, otherPlayerDeathSounds)}
                    </div>
                    : null}
                {badTimesSounds !== undefined && badTimesSounds.length > 0 ?
                    <div className="sound-type">Bad Times:
                        {mapSoundList(cdnUrl, badTimesSounds)}
                    </div>
                    : null}
                {placeSuspiciousSounds !== undefined && placeSuspiciousSounds.length > 0 ?
                    <div className="sound-type">Place Suspicious:
                        {mapSoundList(cdnUrl, placeSuspiciousSounds)}
                    </div>
                    : null}
                {placeNiceSounds !== undefined && placeNiceSounds.length > 0 ?
                    <div className="sound-type">Place Nice:
                        {mapSoundList(cdnUrl, placeNiceSounds)}
                    </div>
                    : null}

                {niceNpcSounds !== undefined && niceNpcSounds.length > 0 ?
                    <div className="sound-type">Nice NPC:
                        {mapSoundList(cdnUrl, niceNpcSounds)}
                    </div>
                    : null}

                {shadyNpcSounds !== undefined && shadyNpcSounds.length > 0 ?
                    <div className="sound-type">Shady NPC:
                        {mapSoundList(cdnUrl, shadyNpcSounds)}
                    </div>
                    : null}
                {lookElsewhereSounds !== undefined && lookElsewhereSounds.length > 0 ?
                    <div className="sound-type">Look Elsewhere:
                        {mapSoundList(cdnUrl, lookElsewhereSounds)}
                    </div>
                    : null}
                {actionGoodSounds !== undefined && actionGoodSounds.length > 0 ?
                    <div className="sound-type">Action Good:
                        {mapSoundList(cdnUrl, actionGoodSounds)}
                    </div>
                    : null}
                {actionEvilSounds !== undefined && actionEvilSounds.length > 0 ?
                    <div className="sound-type">Action Evil:
                        {mapSoundList(cdnUrl, actionEvilSounds)}
                    </div>
                    : null}
                {actionChaoticSounds !== undefined && actionChaoticSounds.length > 0 ?
                    <div className="sound-type">Action Chaotic:
                        {mapSoundList(cdnUrl, actionChaoticSounds)}
                    </div>
                    : null}
                {actionLawfulSounds !== undefined && actionLawfulSounds.length > 0 ?
                    <div className="sound-type">Action Lawful:
                            {mapSoundList(cdnUrl, actionLawfulSounds)}
                    </div>
                    : null}
                {crowdControlSounds !== undefined && crowdControlSounds.length > 0 ?
                    <div className="sound-type">Crowd Control:
                        {mapSoundList(cdnUrl, crowdControlSounds)}
                    </div>
                    : null}
                {eventGoodSounds !== undefined && eventGoodSounds.length > 0 ?
                    <div className="sound-type">Event Good:
                        {mapSoundList(cdnUrl, eventGoodSounds)}
                    </div>
                    : null}
                {eventBadSounds !== undefined && eventBadSounds.length > 0 ?
                    <div className="sound-type">Event Bad:
                        {mapSoundList(cdnUrl, eventBadSounds)}
                    </div>
                    : null}
                {previewSounds !== undefined && previewSounds.length > 0 ?
                    <div className="sound-type">Preview:
                        {mapSoundList(cdnUrl, previewSounds)}
                    </div>
                    : null}
                {eventSuspiciousSounds !== undefined && eventSuspiciousSounds.length > 0 ?
                    <div className="sound-type">Event Suspicious:
                        {mapSoundList(cdnUrl, eventSuspiciousSounds)}
                    </div>
                    : null}
                {eventBetrayedSounds !== undefined && eventBetrayedSounds.length > 0 ?
                    <div className="sound-type">Event Betrayed:
                        {mapSoundList(cdnUrl, eventBetrayedSounds)}
                    </div>
                    : null}
                {placeCreepySounds !== undefined && placeCreepySounds.length > 0 ?
                    <div className="sound-type">Place Creepy:
                        {mapSoundList(cdnUrl, placeCreepySounds)}
                    </div>
                    : null}
            </div>
        );
    }
}

// PropTypes
SentientJewel.propTypes = {
    ReportError: PropTypes.func.isRequired
}

export default SentientJewel;