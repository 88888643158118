import React from 'react';

// recursive text colorizor
export default function Colorize(uncoloredText) {
    if (uncoloredText === undefined || uncoloredText.length === 0)
        return

    let textParts = uncoloredText.replace(/\\#/gm, '#').replace("\\!", '!')

    //NOTE: THIS RATS NEST, IS A RATS NEST, PLEASE SUBMIT CODE TO CLEAN THIS UP (THANKS!):

    var coloredParts = textParts.split(/(?:<RGB=|<rgb=)(#\w{3,})(?:>)(.)(<\/RGB>|\/rgb>|)(.*)/g).filter(Boolean)
    if (coloredParts.length > 1) {
        // colored text in first position:
        if (coloredParts[0].indexOf("#") === 0) {
            // only 3 groups found:
            if (coloredParts.length === 3) {
                // reset found:
                if (coloredParts[2].indexOf("</") !== -1) {
                    // need to chop off at the RGB and colorize farther
                    var resetParts = coloredParts[2].split(/(<\/RGB>|<\/rgb>)/g).filter(Boolean)
                    return (
                        <span>
                            <span style={{ color: coloredParts[0] }}>{coloredParts[1]}{resetParts[0]}</span><span>{Colorize(resetParts[2])}</span>
                        </span>
                    )
                    // no reset:
                } else {
                    return (
                        <span style={{ color: coloredParts[0] }}>{coloredParts[1]}{Colorize(coloredParts[2])}</span>
                    )
                }
                // four groups found, recursing with the current color:
            } else {
                return (
                    <span style={{ color: coloredParts[0] }}>{coloredParts[1]}{Colorize(coloredParts[3])}</span>
                )
            }
        } else {
            if (coloredParts[1].indexOf("#") === 0) {
                // we don't have a color in the first position
                if (coloredParts[3].indexOf("</") !== 0) {
                    return (
                        <span style={{ color: "#fff" }}>{coloredParts[0]}<span style={{ color: coloredParts[1] }}>{Colorize(coloredParts.slice(2,).join('').replace(/<\/RGB>|<\/rgb>/, ''))}</span></span>
                    )
                } else {
                    return (
                        <span>{coloredParts[0]}<span style={{ color: coloredParts[1] }}>{Colorize(coloredParts.slice(2,).join('').replace(/<\/RGB|\/rgb>/, ''))}</span></span>
                    )
                }
            } else {
                return (
                    coloredParts.join()
                )
            }
        }
    } else {
        if (coloredParts !== undefined && coloredParts.length === 1) {
            if (coloredParts[0].indexOf("<") === 0) {
                coloredParts = coloredParts[0].split(/(?:<RGB|<rgb=)(#\w{3,})(?:>)(.*)/g).filter(Boolean)
                if (coloredParts[0].indexOf("#") === 0) {
                    return (
                        <span style={{ color: coloredParts[0] }}>{Colorize(coloredParts.slice(1, coloredParts.length).join())}</span>
                    )
                } else {
                    if (coloredParts[1] !== undefined && coloredParts[1].indexOf("#") === 0) {
                        return (
                            <span>{coloredParts[0]} <span style={{ color: coloredParts[1] }}>{Colorize(coloredParts.slice(2, coloredParts.length).join())}</span></span>
                        )
                    } else {
                        return (
                            coloredParts.join()
                        )
                    }
                }
            } else {

                return (
                    coloredParts[0]
                )
            }
        }
        return null;
    }
}