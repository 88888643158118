import React, { Component } from 'react'
import axios from 'axios'
import ServerConfig from './ServerConfig.js'
import Search from './Search'
import GearFilter from './GearFilter'
import GearList from './GearList'
import RouteItem from './RouteItem'
import AboutUs from './AboutUs'

import '../css/App.css'

import GearListLogo from '../images/vok_logo.png'

//** {App} is the main UI component */
class App extends Component {
  // Starting state:
  constructor(props) {
    super(props)
    this.state = {
      CurrentIndexList: [],
      CurrentItemName: -1,
      CurrentPage: 1,
      NumPages: 1,
      input: "",
      filters: {},
      LastResetId: 0,
      Error: null,
      FullTextSearch: false,
      HasResults: false,
      staticPage: null
    }
  }

  // Function runs every time the component Renders or initailizes:
  componentDidMount() {
    // Get the inital list from api:
    this.CheckApi()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.CheckRoute()
    }
  }

  // Local error reporting function:
  ReportError(error) {
    console.log(error)
    this.setState({
      CurrentIndexList: [],
      CurrentItemName: -1,
      Error: String(error)
    })
  }

  CheckRoute = () => {
    const { pathname } = this.props.location
    var urlParts = pathname.split('/');
    var route = urlParts[urlParts.length - 1].replace(/_/gi, ' ');
    if (this.props.location.pathname.toLowerCase().includes("/quest/")) {
      if (route !== undefined) {
        this.setState({
          Loaded: true,
          filters: { selectedQuest: route },
          CurrentPage: 1,
          NumPages: 1
        },
          () => {
            if (this.filtering !== undefined) {
              this.filtering._setQuest(route)
              this.GetList()
            }
          }
        )
      }
    } else if (this.props.location.pathname.toLowerCase().includes("/adventure-pack/")) {
      if (route !== undefined) {
        this.setState({
          Loaded: true,
          filters: { selectedAdventurePack: route },
          CurrentPage: 1,
          NumPages: 1
        },
          () => {
            if (this.filtering !== undefined) {
              this.filtering._setAdventurePack(route)
              this.GetList()
            }
          })
      }
    }
    else {
      // no extra route
      this.setState({
        Loaded: true
      });
    }
  }

  // Upon initial load of the client, this function will check to see if the remote API data source is accessable.  
  CheckApi = () => {
    let remoteHost = ServerConfig.getInstance()
    let apiendpoint = remoteHost.getAPIEndpoint();
    axios.get(
      apiendpoint + "/livecheck",
      { headers: { 'Content-Type': 'application/json' }, timeout: 5000 }
    ).then(res => {
      if (res.status !== undefined && res.status === 200) {
        // check for incoming routes
        this.CheckRoute()
      } else {
        // THROW AERRORARSARORZ!
        this.ReportError("Could not connect to API: " + res.status ? res.status : "Unknown")
      }
    }
    )
      .catch((error) => {
        this.ReportError(error)
      });
  }

  // Retrieves Index or Search result data from API
  GetList = () => {
    if (this.state.Loaded !== undefined && this.state.Loaded === true) {
      let remoteHost = ServerConfig.getInstance()
      let apiendpoint = remoteHost.getAPIEndpoint();
      const fullText = this.state.FullTextSearch;
      const { selectedMinMaxLevel, selectedItemType, selectedItemSubType, selectedEquipsToSlot, selectedQuest, selectedAdventurePack } = this.state.filters;
      const postData = {
        FullTextSearch: (fullText !== null && (fullText !== false && this.state.input !== undefined) ? this.state.input : null),
        NameSearch: (this.state.input !== undefined && fullText === false ? this.state.input : null),
        MinimumLevel: (selectedMinMaxLevel !== undefined ? selectedMinMaxLevel[0] : 0),
        MaximumLevel: (selectedMinMaxLevel !== undefined ? selectedMinMaxLevel[1] : 30),
        ItemType: (selectedItemType !== undefined ? selectedItemType : null),
        ItemSubType: (selectedItemSubType !== undefined ? selectedItemSubType : null),
        SearchLocations: (selectedEquipsToSlot !== undefined ? selectedEquipsToSlot : null),
        Quest: (selectedQuest !== undefined ? selectedQuest : null),
        AdventurePack: (selectedAdventurePack !== undefined ? selectedAdventurePack : null),
        Page: (this.state.CurrentPage)
      }
      axios.post(
        apiendpoint + "/items", postData,
        { headers: { 'Content-Type': 'application/json' } }
      ).then(res => {
        this.setState(
          prevState => (
            {
              GearList: res.data.results,
              CurrentIndexList: res.data.results,
              CurrentItemName: prevState.CurrentItemName,
              NumPages: res.data.numPages,
              HasResults: true
            }));
      }
      )
        .catch((error) => {
          this.ReportError(error)
        });
    } else {
      this.ReportError("Could not load remote API.")
      console.log("Could not load remote API.")
    }
  }

  // Selects an Item ID
  SelectItem = (id) => {
    this.setState(prevState => (
      {
        CurrentIndexList: prevState.CurrentIndexList,
        CurrentItemName: id
      })
    )
  }

  // Button Click Event Handler for Submit
  SubmitNameSearch(e) {
    this.props.history.push('/')
    // reset pagination
    this.setState(
      {
        CurrentPage: 1
      },
      () => {
        this.GetList()
      }
    )
  }

  // Sets the index list to the initial state
  resetList = (e) => {
    this.props.history.push('/')
    this.setState(
      prevState => (
        {
          CurrentIndexList: [],
          CurrentItemName: -1,
          LastResetId: prevState.LastResetId + 1,
          filters: {},
          FullTextSearch: false,
          CurrentPage: 1,
          NumPages: 1,
          input: "",
          HasResults: false
        }
      )
    )
    // clears search name:
    if (this.input1 !== undefined)
      this.input1.clearDefault(e, true)
  }

  // Clears the selected Item Id
  resetId = () => {
    this.setState(
      prevState => (
        {
          CurrentIndexList: prevState.CurrentIndexList,
          CurrentItemName: -1,
          LastResetId: prevState.LastResetId + 1,
          filters: {}
        }
      ))
  }

  // Event handler for when search filters are changed:
  // The option 
  onChangeSearchFilters = (itemFilterOptionArray) => {
    // pushes the option to state and use the callback to perform the filter actions:
    this.setState(
      {
        CurrentPage: 1
      }
      , () => {
        this.setState(
          itemFilterOptionArray,
          () => this.GetList()
        );
      }
    );
  }

  // Key Press detector, used when the name search box has a keypress:
  testForEnterKey = (e) => {
    if (e.key === 'Enter') {
      this.SubmitNameSearch()
    }
    return
  }

  // search handler for search input box
  onChangeHandler(e) {
    // save the input to state
    this.setState(
      {
        input: e.target.value
      }
    )
  }

  // Next Page Button function
  nextPage() {
    var nextPageId = this.state.CurrentPage + 1;
    if (nextPageId <= this.state.NumPages) {
      this.setState(
        {
          CurrentPage: nextPageId,
        },
        () => {
          this.GetList()
        }
      )
    }
  }

  // Previous Page Button function
  prevPage() {
    if (this.state.CurrentPage !== undefined && this.state.CurrentPage > 1) {
      var prevPageId = this.state.CurrentPage - 1;
      if (prevPageId <= this.state.NumPages && prevPageId > 0) {
        this.setState(
          {
            CurrentPage: prevPageId,
          },
          () => {
            this.GetList();
          }
        )
      }
    }
  }

  handleCheckboxChange(e) {
    this.setState(
      prevState => (
        {
          FullTextSearch: !prevState.FullTextSearch
        })
    )
  }

  // Main function to display everything on the UI side:
  render() {
    if (this.state.CurrentIndexList !== undefined &&
      this.state.Loaded !== undefined &&
      (this.state.Error === undefined || this.state.Error === null) &&
      this.state.staticPage === null
    ) {

      let { CurrentPage, NumPages } = this.state;
      var _currentPage = 0;
      var _totalPages = 0;
      if (CurrentPage !== undefined) {
        _currentPage = parseInt(CurrentPage);
      }
      if (NumPages !== undefined) {
        _totalPages = parseInt(NumPages);
      }

      return (
        <div id="master">
          <div className="App row">
            <div className="col-xs-12 col-sm-12 col-lg-4 d-print-none" id="searching">
              <div id="gear_list_logo" className="row">
                <div className="media d-flex align-items-center">
                  <img className="rounded mx-auto d-block" src={GearListLogo}
                    alt="Vault of Kundarak - DDO Item Database"
                    onClick={this.resetList} />
                  <div className="media-body logo-text">
                    <h4 className="m-0 text-light">Vault of Kundarak</h4>
                    <p className="font-weight-light mb-0 text-light">DDO Item Database</p>
                  </div>
                </div>
              </div>
              <div id="search_box" className="row">
                <Search ref={input1 => this.input1 = input1}
                  onChange={this.onChangeHandler.bind(this)}
                  onKeyDown={this.testForEnterKey.bind(this)}
                  resetList={this.resetList.bind(this)} />
              </div>
              <div id="full_text_toggle" className="row" title="Using this will search all text in the item.">
                <div className="custom-control custom-switch">
                  <input id="full-text-toggle" className="custom-control-input"
                    type="checkbox"
                    checked={this.state.FullTextSearch}
                    onChange={this.handleCheckboxChange.bind(this)} />
                  <label htmlFor="full-text-toggle"
                    className="custom-control-label">Full Text Search</label>
                </div>
              </div>
              <div id="gear_filter" className="row">
                <GearFilter
                  ReportError={this.ReportError.bind(this)}
                  ref={filtering => { this.filtering = filtering }}
                  onChangeHandler={this.onChangeSearchFilters.bind(this)}
                  resetCounter={this.state.LastResetId} />
              </div>
              <hr />
              <div id="button_row_s-r" className="row">
                <div className="col-6">
                  <button className="btn btn-dark" name="submit"
                    onClick={this.SubmitNameSearch.bind(this)}>Submit
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-dark" name="reset"
                    onClick={this.resetList.bind(this)}>Reset
                  </button>
                </div>
              </div>
            </div>
            {this.state.HasResults === true ?
              <div className="col-xs-6 d-print-none search-results">
                <div className="row" id="results">
                  {_totalPages > 1 ?
                    <div id="pagination" className="text-center col-12">
                      <div>Page {_currentPage} of {_totalPages}</div>
                    </div>
                    :
                    <div id="pagination" className="text-center col-12">
                      <div>Page 1 of 1</div>
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-6">
                    {(_totalPages > 1 && _currentPage > 1) ?
                      <button className="btn btn-dark paginationButton"
                        onClick={this.prevPage.bind(this)}>
                        Previous
                      </button>
                      :
                      null
                    }
                  </div>
                  <div className="col-6">
                    {(_totalPages > 1 && _currentPage < _totalPages) ?
                      <button className="btn btn-dark paginationButton"
                        onClick={this.nextPage.bind(this)}>
                        Next
                      </button>
                      :
                      null
                    }
                  </div>
                </div>
                <div className="row">
                  <hr />
                  {this.state.CurrentIndexList.length === 0 && this.state.HasResults === true ?
                    <div className="no-search-results col-12" onClick={this.resetList}>No results</div>
                    :
                    <GearList GearList={this.state.CurrentIndexList} SelectItem={this.SelectItem} />
                  }
                </div>
                <div className="row">
                  <div className="col-6">
                    {(_totalPages > 1 && _currentPage > 1) ?
                      <button className="btn btn-dark paginationButton"
                        onClick={this.prevPage.bind(this)}>
                        Previous
                      </button>
                      :
                      null
                    }
                  </div>
                  <div className="col-6">
                    {(_totalPages > 1 && _currentPage < _totalPages) ?
                      <button className="btn btn-dark paginationButton"
                        onClick={this.nextPage.bind(this)}>
                        Next
                      </button>
                      :
                      null
                    }
                  </div>
                </div>
                <div className="row" id="results">
                  {_totalPages > 1 ?
                    <div id="pagination" className="text-center col-12">
                      <div>Page {_currentPage} of {_totalPages}</div>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
              : <div className="row"></div>
            }
            <div className="col">
              <div id="display">
                <RouteItem CurrentItemName={this.state.CurrentItemName} SelectItem={this.SelectItem.bind(this)} ReportError={this.ReportError.bind(this)} />
              </div>
            </div>
          </div>
          <div className="row">
            <AboutUs />
          </div>
        </div>
      );
    } else {
      if (this.state.Error !== null) {
        return (
          <div className="error-background">
            <div className="error-message">
              We are having troubles reaching the item-data-service at this time:
            {this.state.Error ?
                <div>{this.state.Error}</div> : null}
              <br />
              Please try again at a later time.
            </div>
          </div>
        );
      } else {
        return (
          <div className="loading-message">LOADING...</div>
        );
      }
    }
  }
}

export default App;
