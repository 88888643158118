import React, { Component } from 'react'
import ServerConfig from './ServerConfig'
import axios from 'axios'
import GearList from './GearList'

const iconicRaces = [
    { label: "Aasimar Scourge", value: "Aasimar Scourge" },
    { label: "Bladeforged", value: "Bladeforged" },
    { label: "Deep Gnome", value: "Deep Gnome" },
    { label: "Purple Dragon Knight", value: "Purple Dragon Knight" },
    { label: "Tiefling Scoundrel", value: "Tiefling Scoundrel" },
    { label: "Shadar-kai", value: "Shadarkai" },
    { label: "Sun Elf (Morninglord)", value: "Morninglord" }
]

export class StarterGear extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GearLists: []
        };
    }

    _selectItem(item) {
        if (this.props.SelectItem !== undefined) {
            this.props.SelectItem(item)
        }
    }

    // built-in: used to detect changes after initialization
    componentDidMount() {
        iconicRaces.forEach((race) => {
            this.loadStarterGearForRace(race)
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.GearLists.length === iconicRaces.length)
            return true
        else
            return false
    }

    fetchStarterGearForRace = async (raceType) => {
        let remoteHost = ServerConfig.getInstance()
        let apiendpoint = remoteHost.getAPIEndpoint();

        var returnData = {}

        await axios.get(
            apiendpoint + "/items/starter-gear/" + raceType.value,
            { headers: { 'Content-Type': 'application/json' }, timeout: 5000 }
        ).then((response) => {
            returnData = response.data.results
        }).catch((error) => {
            this.props.ReportError(error)
        })

        return returnData
    }

    // Fetches and stores a list of items
    loadStarterGearForRace = async (race) => {
        var items = await this.fetchStarterGearForRace(race)
        this.setState((prevState) => {
            const list = [...prevState.GearLists]
            return {
                GearLists: list.concat({ race: race, items: items })
            }
        });
    }

    render = () => {
        if (this.state.GearLists !== undefined && this.state.GearLists.length > 0) {
            const { GearLists } = this.state
            return (
                <div className="adventure-packs">
                    {GearLists.map((iconic) =>
                        <div key={iconic.race.value}>
                            <div className="engolden">{iconic.race.label}:</div><GearList SelectItem={this._selectItem.bind(this)} GearList={iconic.items} />
                        </div>
                    )}
                </div>
            )
        }
        else
            return <div>not working.</div>
    }
}

export default StarterGear;