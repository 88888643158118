import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../css/Search.css'

// Navigation menu on the top of the root, app and list pages
class Search extends Component {
    state = {
        input: ''
    }

    onFieldChange(e) {
        this.props.onChange(e);
    }

    onKeyDown(e) {
        this.props.onKeyDown(e);
    }

    clearDefault(e, force) {
        var forceClear = false
        if (force !== undefined) {
            forceClear = force
        }

        if (e !== null && (e.target.value !== undefined && e.target.value === "Type here to Search by name...")) {
            e.target.value = ""
        }

        if (this !== undefined && (this.input.value === "Type here to Search by name..." || forceClear)) {
            this.input.value = "Type here to Search by name..."
        }
    }

    render() {
        return (
            <input name="search" id="searchInput" className="form-control gf-select" onClick={this.clearDefault} onChange={this.onFieldChange.bind(this)} onKeyDown={this.onKeyDown.bind(this)} defaultValue="Type here to Search by name..." ref={input => this.input = input}></input>
        );
    }
}

// PropTypes
Search.propTypes = {
    onKeyDown: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    resetList: PropTypes.func.isRequired
}

export default Search;