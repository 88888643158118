import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Link
} from "react-router-dom";

import '../css/GearListItem.css'

// Strips colors from names:
const filterColors = (uncoloredText) => {
    var coloredParts = uncoloredText.split(/(<(?:rgb|RGB)=#.*?<\/rgb>)/g);
    var allParts = []
    coloredParts.forEach((colorPart, i) => {
        if (colorPart.length > 0 && (colorPart.indexOf("<rgb") >= 0 || colorPart.indexOf("<RGB") >= 0)) {
            var parsedPart = colorPart.split(/<(?:(?:rgb|RGB)=#(.*?))>(.*?)<(?:\/rgb)>/g).filter(Boolean);
            allParts.push(<span key={i}>{parsedPart[1]}</span>)
        } else {
            // this may remove new lines?
            if (colorPart.length > 0) {
                allParts.push(colorPart);
            }
        }
    });

    return allParts;
}

export class GearListItem extends Component {
    render() {
        const { routingName, displayName, effects, minimumLevel, icon, itemType, itemSubType } = this.props.GearListItem
        return (
            <div className="gear-list-item-container">
                <Link className="gear-list-item" onClick={this.props.SelectItem.bind(this, routingName)} to={"/item/" + routingName}>
                    <div className="gear-list-item-icon">
                        <img src={icon} alt={displayName} />
                    </div>
                    <div className="gear-list-item-text">
                        {displayName}
                        <div className="gear-list-item-details">
                            {minimumLevel ? "ML: " + minimumLevel + " - " : null}
                            {itemType ? itemType : null}{itemSubType ? ": " + itemSubType : null}
                            {effects != null && effects.length > 0 ?
                                <ul>
                                    {effects.map((slotOrItem, i) =>
                                        <li key={i}> {filterColors(slotOrItem)}</li>
                                    )}
                                </ul>
                                : null}
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

// PropTypes
GearListItem.propTypes = {
    GearListItem: PropTypes.shape({
        displayName: PropTypes.string.isRequired
    })
}

export default GearListItem
