import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Examine from './Examine'
import Modal from 'react-modal'
import axios from 'axios'

import ServerConfig from './ServerConfig'

import cookie from 'react-cookies'

import '../css/Examine.css'
import '../css/treasure/copper.css'
import '../css/treasure/silver.css'
import '../css/treasure/gold.css'
import '../css/treasure/platinum.css'
import '../css/ReportIssueModal.css'

// Themes are based on coinage
const Themes = {
    COPPER: 'copper',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum'
}

Modal.setAppElement('#root')

export class Gear extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CurrentGear: {},
            CurrentItemName: -1,
            style: "",
            reportingModalIsOpen: false,
            hideEffectDescriptions: false
        };
    }

    showModal = () => {
        this.setState({ reportingModalIsOpen: true }
        );
        this.forceUpdate();
    }

    hideModal = () => {
        this.setState({ reportingModalIsOpen: false }
        );
        this.forceUpdate();
    }

    componentDidMount() {
        // set username from cookie if available:
        var checkRememberUsername = false;
        var reportingName = cookie.load('reportingName')
        if (reportingName !== undefined && reportingName.length > 0) {
            checkRememberUsername = true;
        }
        var hideEffects = false;
        var hideEffectDescriptions = cookie.load('hideEffectDescriptions')
        if (hideEffectDescriptions !== undefined && hideEffectDescriptions.length > 0) {
            hideEffects = (hideEffectDescriptions === 'true');
        }
        this.setState({
            reportingName: reportingName,
            rememberUsernameChecked: checkRememberUsername,
            hideEffectDescriptions: hideEffects
        })
        if (this.props.itemName !== -1) {
            this.getData()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.itemName !== prevState.CurrentItemName) {
            if (this.props.itemName !== prevProps.itemName) {
                if (this.props.itemName === -1) {
                    this.setState(({ CurrentGear: {}, CurrentItemName: this.props.itemName, style: this.getTheme() }))
                } else {
                    this.getData()
                }
            }
        }
    }

    catchError(error) {
        console.log(error)
        if (this.props.ReportError !== undefined) {
            this.props.ReportError(error)
        } else {
            console.log("Error reporting broken.")
        }
    }

    getData() {
        if (this.props.itemName !== -1) {
            let remoteHost = ServerConfig.getInstance()
            let apiendpoint = remoteHost.getAPIEndpoint();
            // make this a get request and include the itemName as the "ItemId" parameter

            // fix web encoding:
            var fixedName = this.props.itemName.replace("?", "%3F")

            fetch(apiendpoint + "/items/" + fixedName)
                .then((json) => json.json())
                .then((data) => {
                    // does this item have any other component needs?

                    this.setState({ CurrentGear: data, CurrentItemName: this.props.itemName, style: this.getTheme(data.treasureType, data.itemType) })

                    const pageName = data.displayName ? data.displayName : null;
                    if (pageName != null) {
                        window.document.title = pageName + " - Vault of Kundarak - DDO Item Database"
                        document.getElementById("scroll-marker").scrollIntoView()
                    }
                })
                .catch((error) => this.catchError(error))
        }
    }

    // this should take an item id and a text message
    ReportIssue() {
        this.showModal()
    }

    sendIssue() {
        if (this.state.CurrentItemName !== undefined && this.state.CurrentItemName.length > 0) {
            var badItem = this.state.CurrentItemName
            var issueText = (this.state.issueText ? this.state.issueText : null)
            let remoteHost = ServerConfig.getInstance()
            let apiendpoint = remoteHost.getAPIEndpoint();
            const postData = {
                itemDisplayName: badItem,
                Username: (this.state.reportingName ? this.state.reportingName : ""),
                message: (issueText ? issueText : "")
            }
            // make this a get request and include the itemName
            axios.post(
                apiendpoint + "/items/report", postData
            ).catch((error) => {
                this.catchError(error)
            });
        } else {
            this.catchError("Invalid report Id.")
        }
        this.setState({ issueText: null },
            () => this.hideModal()
        )
        if (this.state.rememberUsernameChecked) {
            this.saveUsername()
        }
    }

    clearHiddenEffectDescription() {
        cookie.remove('hideEffectDescriptions')
    }

    clearUsername() {
        cookie.remove('reportingName')
    }

    toggleHideEffectDescriptions() {
        if (this.state.hideEffectDescriptions !== undefined) {
            this.setState(prev => (
                {
                    hideEffectDescriptions: !prev.hideEffectDescriptions
                }), () => {
                    cookie.save('hideEffectDescriptions', this.state.hideEffectDescriptions, { path: "/", maxAge: 60 * 60 * 24 * 365 })
                })
        }
    }

    saveUsername() {
        if (this.state.reportingName !== undefined) {
            cookie.save('reportingName', this.state.reportingName, { path: "/", maxAge: 60 * 60 * 24 * 365 })
        }
    }

    getTheme = (treasureType, itemType) => {
        var theme = Themes.COPPER;

        // Default is always COPPER:
        switch (treasureType) {
            case null:
            case 0:
            case "Undef":
                theme = Themes.COPPER.valueOf();
                break;
            case 1:
            case "Random":
                theme = Themes.SILVER.valueOf();
                break;
            case 2:
            case "Named":
                theme = Themes.GOLD.valueOf();
                break;
            case 3:
            case "Raid":
                theme = Themes.PLATINUM.valueOf();
                break;
            case 4:
            case "Vendor":
                theme = Themes.COPPER.valueOf();
                break;
            default:
                theme = Themes.COPPER.valueOf();
                break;
        }

        if (itemType !== undefined) {
            switch (itemType) {
                case "Wand":
                    theme = Themes.SILVER.valueOf();
                    break;
                default:
                    break;
            }
        }

        this.setState({
            style: theme
        });
        return theme;
    }

    captureUserInput = (e) => {
        this.setState({ reportingName: e.target.value });
    }

    captureIssueInput = (e) => {
        this.setState({ issueText: e.target.value });
    }

    checkRememberUsername(e) {
        this.setState({ rememberUsernameChecked: e.target.checked },
            () => {
                if (this.state.rememberUsernameChecked === true) {
                    this.saveUsername()
                } else {
                    this.clearUsername()
                }
            });
    }

    render() {
        const { rememberUsernameChecked, reportingName, hideEffectDescriptions } = this.state;
        if (this.props.itemName !== undefined && this.state.CurrentItemName !== -1) {
            return (
                <span id="scroll-marker">
                    <Examine DataItem={this.state.CurrentGear} style={this.state.style} ReportError={this.catchError.bind(this)} ReportIssue={this.ReportIssue.bind(this)} hideEffectDescriptions={hideEffectDescriptions} toggleHideEffectDescriptions={this.toggleHideEffectDescriptions.bind(this)} />
                    <Modal className="modal-main" overlayClassName="modal" isOpen={this.state.reportingModalIsOpen} onRequestClose={this.hideModal}>
                        <div className="draggable col">
                            <div className="row">
                                Enter your name: <input maxLength="64" className="userName" onChange={this.captureUserInput.bind(this)} defaultValue={reportingName}></input>
                            </div>
                            <div className="row">
                                Remember Username? <input type="checkbox" onChange={this.checkRememberUsername.bind(this)} defaultChecked={rememberUsernameChecked} />
                            </div>
                            <div className="row">
                                What is the problem with {this.state.CurrentGear.displayName ? this.state.CurrentGear.displayName : String(this.state.CurrentItemName)} ?
                            </div>
                            <div className="row">
                                <textarea maxLength="1024" className="reportIssueInput" onChange={this.captureIssueInput.bind(this)}></textarea>
                            </div>
                            <div className="row">
                                <button className="modal-main-button submit-button" onClick={this.sendIssue.bind(this)}>Submit</button>
                                <button className="modal-main-button top-close-button" onClick={this.hideModal}>Cancel</button>
                            </div>
                        </div>
                    </Modal>
                </span>
            );
        } else {
            return (
                <div className="gear-no-item-selected">
                    Loading...
                </div>
            );
        }
    }
}

// PropTypes
Gear.propTypes = {
    itemName: PropTypes.string.isRequired
}

export default Gear
