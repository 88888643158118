import React, { Component } from 'react'
import {
    Switch,
    Route,
    useParams
} from "react-router-dom";

import AboutUs from './AboutUs'
import AdventurePacks from './AdventurePacks'
import Gear from './Gear'
import Quests from './Quests'
import StarterGear from './StarterGear'

export class RouteItem extends Component {

    render() {
        let { CurrentItemName, ReportError, SelectItem } = this.props;
        return (
            <Switch>
                <Route exact path="/" children={<AdventurePacks {...this.props} ReportError={ReportError} />} />
                <Route path="/about" children={<AboutUs {...this.props} ReportError={ReportError} />} />
                <Route path="/quests" children={<Quests {...this.props} ReportError={ReportError} />} />
                <Route path="/adventure-packs" children={<AdventurePacks {...this.props} ReportError={ReportError} />} />
                <Route path="/starter-gear" children={<StarterGear {...this.props} ReportError={ReportError} SelectItem={SelectItem} />} />
                <Route path="/item/:id" >
                    <Child itemName={CurrentItemName} {...this.props} ReportError={ReportError} />
                </Route>
            </Switch>
        );
    }
}


function Child(props) {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();

    // initial load will not have an id, but subsequent requests will:
    if (props.itemName !== id) {
        // store name in the id
        if (props.itemName !== -1) {
            id = props.itemName
        }
    }

    return (
        <Gear itemName={id} ReportError={props.ReportError.bind(props)} />
    );
}

export default RouteItem;