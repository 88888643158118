import React from 'react'
import GearListItem from './GearListItem'
import PropTypes from 'prop-types'

class GearList extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GearList === nextProps.GearList) {
            return false;
        } else {
            return true;
        }
    }

    renderRows() {
        const rows = []
        this.props.GearList.map((gearList, i) => (
            rows.push(<GearListItem key={i} GearListItem={gearList} SelectItem={this.props.SelectItem} />)
        ));
        return rows
    }

    render() {
        // return multiple item:
        if (this.props.GearList !== undefined) {
            return (
                <div className="gear-list">
                    {this.renderRows()}
                </div>
            );
        } else {
            return (
                <div>nodata.... </div>
            );
        }
    }
}

// PropTypes
GearList.propTypes = {
    SelectItem: PropTypes.func.isRequired,
    GearList: PropTypes.array.isRequired
}

export default GearList
